















































































import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			members: [],
			title: "",
			subtitle: "",
			level: "",
			target: [] as any[],

			targetCurrentIndex: 0,
			searchTarget: "" as string,
			showTargets: false
		};
	},
	created() {
		if (this.$store)
			this.$store
				.dispatch("GET_CLUB_MEMBERS")
				.then(members => {
					this.members = members;
				})
				.catch(err => {});
	},
	mounted() {
		(this.$refs.startInput as HTMLDivElement).focus();
		addEventListener("keydown", e => {
			if (e.keyCode == 27) this.$emit("isUpdated", false);
		});
	},
	methods: {
		userInputKeyPress(e: any) {
			if (this.targetCurrentIndex >= this.getNotJoinMembers.length)
				this.targetCurrentIndex = this.getNotJoinMembers.length;
			switch (e.keyCode) {
				case 13:
					this.appendTargetItem(this.targetCurrentIndex);
					this.targetCurrentIndex = 0;
					break;
				case 8:
					if (this.searchTarget == "") {
						this.target.pop();
					}
					break;
				case 38:
					if (this.targetCurrentIndex > 0) this.targetCurrentIndex--;
					break;
				case 40:
					if (
						this.targetCurrentIndex <
						this.getNotJoinMembers.length - 1
					)
						this.targetCurrentIndex++;
					break;
			}
		},
		create() {
			this.$store.commit("pushLoading", {
				name: "AWARD",
				message: "동아리 수상 생성 중"
			});
			this.$store
				.dispatch("AWARD", {
					title: this.title,
					subtitle: this.subtitle,
					level: this.level,
					target: this.target.map(x => x._id)
				})
				.then(award => {
					this.$store.commit("clearLoading", "AWARD");
					this.$emit("isUpdated", false);
				})
				.catch(err => {});
		},
		appendTargetItem(idx: number) {
			let member = this.getNotJoinMembers[idx];
			if (member) {
				this.searchTarget = "";
				this.target.push(member);
			}
		},
		removeTargetItem(idx: number) {
			this.target.splice(idx, 1);
		},
		hideTargets() {
			setTimeout(() => {
				this.showTargets = false;
			}, 100);
		}
	},
	computed: {
		getNotJoinMembers(): any[] {
			return this.members.filter(
				(member: any) =>
					this.target.findIndex(x => member._id == x._id) == -1 &&
					member.name.indexOf(this.searchTarget) != -1
			);
		}
	}
});
