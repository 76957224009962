













import Vue from "vue";
export default Vue.extend({
	props: {
		data: Object
	},
	data() {
		return {
			focusInputIndex: -1,
			currentSelectIndex: 0,
			members: [],
			showContextMenu: false
		};
	}
});
