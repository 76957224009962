




















import Vue from "vue";
import AwardBox from "../../components/Page/AwardBox.vue";
import AwardBoxCreatePopup from "../../components/Page/AwardBoxCreatePopup.vue";
export default Vue.extend({
	name: "Awards",
	components: {
		AwardBox,
		AwardBoxCreatePopup
	},
	data() {
		return {
			awards: [] as any,
			showContextMenu: false,
			isShowAwardCreatePopup: false,
			currentId: "",

			members: []
		};
	},
	created() {
		this.reload();
	},
	methods: {
		reload() {
			this.isShowAwardCreatePopup = false;
			this.$store.commit("pushPageLoading", {
				name: "GET_CLUB_AWARDS",
				message: "동아리 수상 불러오는 중"
			});
			this.$store
				.dispatch("GET_CLUB_AWARDS")
				.then(awards => {
					this.$store.commit("clearPageLoading", "GET_CLUB_AWARDS");
					this.awards = awards;
				})
				.catch(err => {});
		},
		appendAwards() {
			this.isShowAwardCreatePopup = true;
		},
		createContextMenu(e: MouseEvent, _id: string) {
			e.preventDefault();
			if (this.isCreateAble) {
				this.showContextMenu = true;
				this.currentId = _id;
				this.$nextTick(() => {
					let contextmenu: any = this.$refs.contextmenu;
					contextmenu.style.left = e.x + "px";
					contextmenu.style.top = e.y + "px";
				});
			}
		},
		remove() {
			if (this.currentId) {
				this.$store.commit("pushPageLoading", {
					name: "AWARD_DELETE",
					message: "동아리 수상 삭제 중"
				});
				this.$store
					.dispatch("AWARD_DELETE", { _id: this.currentId })
					.then(award => {
						this.$store.commit("clearPageLoading", "AWARD_DELETE");
						this.reload();
					})
					.catch(err => {});
			}
		}
	},
	computed: {
		getClub() {
			return this.$store.state.club;
		},
		getClubImage() {
			if (this.$store.state.club.imgPath) return this.$store.state.mainPath + this.$store.state.club.imgPath;
			else return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		},
		isCreateAble() {
			if (this.$store.state.club.ranks) {
				let user = this.$store.state.club.members.find((member: any) => {
					return member.user == this.$store.state.userInformation._id;
				});
				if (user)
					return (
						this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).isAdmin ||
						this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).permission.indexOf(11) != -1
					);
				else return false;
			} else return false;
		}
	}
});
